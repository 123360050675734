<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("SUPPLIERS.EDIT_SUPPLIER") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <supplier-form
              :loading="loading"
              :supplierData="supplier"
              :formErrors="formErrors"
              @supplierSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import SupplierForm from "./partials/SupplierForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultSupplier from "./defaultSupplier";

export default {
  layout: "DashboardLayout",

  components: { SupplierForm },

  mixins: [alertLeave],

  data() {
    return {
      supplier: cloneDeep(defaultSupplier),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("suppliers/get", id);
        this.supplier = this.$store.getters["suppliers/supplier"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    goBack() {
      this.$router.push({ name: "List Suppliers" });
    },

    viewSupplier() {
      this.alertLeave = false;
      this.$router.push({
        name: "View Supplier",
        params: { id: this.supplier.id },
      });
    },

    async handleSubmit(supplier) {
      this.loading = true;
      const supplierData = cloneDeep(supplier);

      try {
        await this.$store.dispatch("suppliers/update", supplierData);
        this.$notify({
          type: "success",
          message: this.$t("SUPPLIERS.SUPPLIER_UPDATED"),
        });
        this.viewSupplier();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
